import "./output.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { motion } from "framer-motion";
import {
  Autoplay,
  Navigation,
  Pagination,
  EffectCoverflow,
} from "swiper/modules";
import { db } from "../config/firebaseConfig";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Countdown from "react-countdown";
import Footer from "./footer";
import FancyText from "@carefully-coded/react-text-gradient";
import { Link, Navigate } from "react-router-dom";
import WavyText from "./WeavyText";

function Home() {
  const [publicidad, setPublicidad] = useState([]);

  useEffect(() => {
    const collectionRef = collection(db, "Publicidad");
    const q = query(collectionRef, where("prioridad", "==", 0));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setPublicidad(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          cliente: doc.data().cliente,
          resumen: doc.data().resumen,
          detalles: doc.data().detalles,
          imagen: doc.data().imagen,
          estrellas: doc.data().estrellas,
          prioridad: doc.data().prioridad,
          abrido: doc.data().abrido,
          horario: doc.data().horario,
          direccion: doc.data().direccion,
          telefono: doc.data().telefono,
          galeria: doc.data().galeria,
        }))
      );
    });
    return unsubscribe;
  }, []);

  const [index, setIndex] = useState([
    {
      id: 1,
      estado: "Puebla",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FPuebla%2FPuebla.png?alt=media&token=e91d6a44-24a1-461a-8ddd-ee028a998b70",
      imgFondo:
        "https://turixcam-images.b-cdn.net/puebla/Zacatlan/Recursos/Puebla.jpg",
    },
    {
      id: 2,
      estado: "Hidalgo",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FHidalgo%2FHidalgo.png?alt=media&token=33721372-ed9e-4095-bb0f-db7ae3964116",
      imgFondo:
        "https://turixcam-images.b-cdn.net/hidalgo/Mineral%20del%20Chico/ChicoCentroFotoOnce.jpg",
    },
    {
      id: 3,
      estado: "Veracruz",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FVeracruz%2FVeracruz.png?alt=media&token=77480979-4b96-4db9-b13c-7156a029fd65",
      imgFondo: "https://turixcam-images.b-cdn.net/Recursos%20WEB/Portadas/V2/Por6c.png",
    },
    {
      id: 4,
      estado: "Tlaxcala",
      portada:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FLetreros%2FTlaxcala_WIP%2FTlaxcala.png?alt=media&token=d409e779-8ae9-4054-9a4f-64a6d88b7a29",
      imgFondo:
        "https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FFondos%2FTlaxcala%2FF1.jpg?alt=media&token=aca050b1-d031-4b25-a36f-6aa7895fb056",
    },
  ]);

  const [replay, setReplay] = useState(true);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div className="img"></div>

      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          style={{ padding: 10, backgroundColor: "rgba(0,0,0,0.010)" }}
        >
          <Container fluid>
            <Navbar.Brand href="#">
              <img
                style={{ height: 40, width: 80 }}
                src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Inicio de sesion
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                style={{
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  style={{
                    fontSize: 20,
                    fontWeight: "bolder",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  Inicio de sesion bajo mantenimiento
                </a>
                <Player
                  src="https://lottie.host/b6c2fd16-4fcb-4874-af84-91aba3702678/f22V7DAdcM.json"
                  loop
                  autoplay
                  style={{ height: "300px", width: "300px" }}
                />
                <Countdown
                  renderer={(props) => (
                    <div
                      style={{
                        fontSize: 18,
                        textAlign: "center",
                        margin: 15,
                        fontWeight: "bolder",
                      }}
                    >
                      {props.days} Dias, {props.hours} Horas, {props.minutes}{" "}
                      minutos para finalizar el mantenimiento.{" "}
                    </div>
                  )}
                  date={Date.now() + 600000000}
                ></Countdown>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <header className="App-header">
        <img
          style={{
            height: 200,
            width: 400,
            marginTop: 50,
            position: "relative",
          }}
          src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
        />
        <div className="example-container div">
          <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} />
        </div>
      </header>
      <div
        style={{
          display: "flex",
          flexDirection: 'column',
          alignSelf: "center",
          width: 1300,
          justifyContent: "center",
          margin: 10,
          paddingTop: 20,
          borderRadius: 15,
          marginBottom: 100,
          height: 750,
          backgroundColor: "rgba(240,240,240,0.4)",
        }}
      >
        <WavyText text="EXPLORA LUGARES" replay={replay} />
        <div
          style={{
            width: 1300,
            alignSelf: "center",
            justifyContent: "center",
            marginTop: 50,
            padding: 0,
            marginBottom: 10,
            height: 650,
          }}
        >
          <Swiper
            // install Swiper modules
            effect={"coverflow"}
            modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
            spaceBetween={20}
            slidesPerView={"auto"}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            autoplay={{
              delay: 2900,
              disableOnInteraction: true,
            }}
            
            onSlidePrevTransitionEnd={() => {
              setReplay(!replay);
              setTimeout(() => setReplay(true), 600);
            }}
            pagination={{ clickable: true }}
          >
            {index.map((item) => (
              <SwiperSlide
                style={{
                  display: "flex",
                  width: 350,
                  height: 500,
                  borderRadius: 15,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  backgroundImage: `url(${item.imgFondo})`,
                  backgroundSize: "cover",
                  backgroundPosition: 'center'
                }}
                key={item.id}
                {...item}
              >
                <Link to={"camaras"} state={{ estado: item.estado }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      height: 130,
                      width: 350,
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      borderRadius: 0,
                      background: "rgba(0,0,0,0.4)",
                      borderWidth: 0,
                      borderColor: "rgba(255,255,255,0.2)",
                    }}
                  >
                    <img
                      src={item.portada}
                      style={{ height: 150, width: 300, alignSelf: "center" }}
                    />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div
        style={{
          alignSelf: "center",
          width: "100%",
          justifyContent: "center",
          margin: 10,
          padding: 10,
          marginBottom: 100,
          height: 190,
          backgroundColor: "rgba(240,240,240,0.4)",
        }}
      >

       
      </div>
      
      <div
        style={{
          width: 1300,
          alignSelf: "center",
          marginTop: -315,
          padding: 10,
          marginBottom: 100,
          height: 650,
        }}
        
      >
        <div style={{display:"flex", padding:30, alignSelf:"center", justifyContent: "center"}}>
          <WavyText text="DESCUBRE" replay={replay} />
        </div>
        
        <Swiper
          // install Swiper modules
          effect={"coverflow"}
          modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={"auto"}
          centeredSlides={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          autoplay={{
            delay: 2400,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {publicidad.map((item) => (
            <SwiperSlide
              style={{
                width: 500,
                height: 400,
                borderRadius: 15,
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                alignContent: "center",
                backgroundImage: `url(${item.imagen})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              key={item.id}
              {...item}
            >
              <Link to={"buscador"}>
                <div
                  style={{
                    width: 500,
                    height: 400,
                    borderRadius: 15,
                    backgroundColor: "rgba(0,0,0,0.3)",
                    justifyContent: "center",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      fontSize: 30,
                      fontWeight: "bolder",
                      color: "white",
                    }}
                  >
                    {item.cliente}
                  </a>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
