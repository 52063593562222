import React, { useState } from "react";
import Avatar from '@mui/material/Avatar';

import { auth, db } from "../config/firebaseConfig";
import ButtonGroup from '@mui/material/ButtonGroup';
import { Link, useLocation } from "react-router-dom";
import {
  collection,
  query,
  onSnapshot,
  where,
  addDoc,
} from "firebase/firestore";
import YouTube from "react-youtube";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Button,
  List,
  ListItem,
  Typography,
  Divider,
  ListItemText,
} from "@mui/material";
import {
  ExpandMoreOutlined,
  SendOutlined,
  SendRounded,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import Comentarios from "./comentarios";
import { Stack } from "react-bootstrap";

function Live() {
  const location = useLocation();

  const title = location.state.title;

  const [camaras, setCamaras] = React.useState([
    {
      id: "",
      title: "",
      videoUri: "",
      descrip: "",
      portada: "",
      background: "",
      background_web: "",
      et1: "",
      et2: "",
      et3: "",
      et4: "",
    },
  ]);

  const [nombre, setNombre] = useState([
    {
      id: " ",
      nombre: " ",
      correo: " ",
      image: " ",
    },
  ]);

  React.useEffect(() => {
    const collectionRef2 = collection(db, "Usuarios");
    const q3 = query(
      collectionRef2,
      where("correo", "==", auth.currentUser.email)
    );
    const unsubscribe2 = onSnapshot(q3, (querySnapshot2) => {
      console.log("Dartos obtenidos");
      setNombre(
        querySnapshot2.docs.map((doc) => ({
          id: doc.id,
          image: doc.data().image,
          nombre: doc.data().nombre,
          correo: doc.data().correo,
        }))
      );
    });
    return unsubscribe2;
  }, []);

  React.useEffect(() => {
    const collectionRef1 = collection(db, "productscams");
    const q2 = query(collectionRef1, where("title", "==", title));
    const unsubscribe1 = onSnapshot(q2, (querySnapshot1) => {
      console.log("Dartos obtenidos");
      setCamaras(
        querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().title,
          videoUri: doc.data().videoUri,
          portada: doc.data().portada,
          descrip: doc.data().descrip,
          background: doc.data().background,
          background_web: doc.data().backWeb,
          et1: doc.data().et1,
          et2: doc.data().et2,
          et3: doc.data().et3,
          et4: doc.data().et4,
        }))
      );
    });
    return unsubscribe1;
  }, []);

  const [showComentarios, setShowComentarios] = useState([
    {
      id: "",
      name: "",
      user: "",
      comentario: "",
      fecha: "",
      image: " ",
    },
  ]);

  React.useEffect(() => {
    const collectionRef = collection(db, "Comentarios");
    const q = query(collectionRef, where("lugar", "==", title));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log("Dartos obtenidos");
      setShowComentarios(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          user: doc.data().user,
          comentario: doc.data().comentario,
          fecha: doc.data().fecha,
          image: doc.data().image,
        }))
      );
    });
    console.log(showComentarios);
    return unsubscribe;
  }, []);

  const [comentario, setComentario] = React.useState("");

  const [datos, setDatos] = React.useState({
    comentario: comentario,
    lugar: title,
    fecha: new Date().toLocaleString(),
    image: " ",
    name: " ",
    user: auth.currentUser.email,
  });

  const onChangeComment = (e) => {
    setComentario(e.target.value);
    setDatos({
      ...datos,
      comentario: e.target.value,
      name: nombre[0].nombre,
      image: nombre[0].image,
    });
  };

  const handleSubmitComentario = async () => {
    if (comentario !== "" && auth.currentUser.email !== null) {
      await addDoc(collection(db, "Comentarios"), datos)
        .then(() => {
          console.log("Comentario agregado correctamente", comentario);
          setComentario("");
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    } else {
      console.log("Error", "No puedes dejar el comentario en blanco.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "transparent",
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          zIndex: -1,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage: `url(${camaras[0].background_web})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>


      <header className="App-header-live">
        <div>
          <YouTube
            videoId={camaras[0].videoUri}
            opts={{ width: 775, height: 399 }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            alignItems: "start",
          }}
        >
          <h2 style={{ marginBottom: 30, alignSelf: "center" }}>{title}</h2>
          <a style={{ fontSize: 18, textAlign: "start" }}>
            {camaras[0].descrip}
          </a>
        </div>
        <div style={{ padding: 30 }}>
          <Accordion
            sx={{
              backgroundColor: "rgba(0,0,0,0)",
              borderColor: "white",
              borderWidth: 1,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined sx={{ color: "white" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ color: "white", fontSize: 18 }}
            >
              Mas sobre este lugar
            </AccordionSummary>
            <AccordionDetails
              sx={{ color: "white", fontSize: 16, textAlign: "left" }}
            >
              <div style={{ marginBottom: "15px" }}>🟩  {camaras[0].et1}</div>
              <div style={{ marginBottom: "15px" }}>🟧  {camaras[0].et2}</div>
              <div style={{ marginBottom: "15px" }}>🟦  {camaras[0].et3}</div>
              <div style={{ marginBottom: "15px" }}>🟥  {camaras[0].et4}</div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div style={{display: "flex", width: "75%"}} >
        
          <div  className="buttonStyle2" style={{width: "65%", justifyContent:"center"}} >
            <Button  
            
              startIcon={<Avatar sx={{ width: 80, height: 80 }} variant="square" src={"https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Fic11.jpeg?alt=media&token=93b48cc9-cb3a-4d48-9bca-724668356ba8"} />}>
            </Button>
          </div>

          <div className="buttonStyle1" style={{width: "65%"}} >
            <Button  
            
              startIcon={<Avatar sx={{ width: 80, height: 80 }} variant="square"   src={"https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Fic2.jpeg?alt=media&token=13728563-3e8b-4660-9cd6-927c83ea2183"} />}>
            </Button>
          </div>

          <div className="buttonStyle3"  style={{width: "65%"}} >
            <Button   
              startIcon={<Avatar sx={{ width: 80, height: 80}}  variant="square"  src={"https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Fic3.jpeg?alt=media&token=3fe4f304-79e1-49f1-9e5e-6102af0c285d"} />}>
            </Button>
          </div>

           <div className="buttonStyle4" style={{width: "65%"}}>
            <Button   
              startIcon={<Avatar  sx={{ width: 80, height: 80 }} variant="square"  src={"https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2Fic4.jpeg?alt=media&token=e4c5e01e-580a-4b0d-99c2-328df23d82b1"} />}>
            </Button>
          </div>
       
        </div>

     

        <div >
          <Box
          className= "textfield"
            component="form"
            noValidate
            style={{display: "flex", margin: 15 ,position: "relative",marginBottom: "20px"}}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "750px",
              
              borderRadius: "5px",
              borderWidth: 1,
            }}
            autoComplete="off"
          >
            <TextField
            
              sx={{ borderRadius: "15px" }}
              id="filled-basic"
              label="Escribe aqui"
              variant="filled"
              fullWidth
              value={comentario}
              onChange={(e) => onChangeComment(e)}
            />
          </Box>
        </div>
        <div >
          <Button
            onClick={handleSubmitComentario}
            variant="contained"
            color="warning"
            style={{  display: "flex", margin: 15 ,position: "relative",marginBottom: "40px",
              width: "300px",
              borderColor: "white",
              
              borderRadius: "15px",
            }}
            endIcon={<SendRounded />}
          >
            Comentar
          </Button>
        </div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
            width: "700px",
            marginTop: "20px",
            alignItems: "start",
          }}
        >
          {showComentarios.map((item) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "start",
                borderColor: "white",
                borderWidth: 1,
                borderRadius: "15px",
                padding: 15,
                marginBottom: "20px",
                width: "100%",
              }}
            >
              <div style={{ flexDirection: 'column' }} >
                <img
                  style={{ height: 48, width: 48, borderRadius: "50%", margin: 5 }}
                  src={item.image}
                />
              </div>
              <div style={{ flexDirection: 'column' }} >
                <h1 style={{ textAlign: "left", fontSize: 19, marginLeft: 10 }}>{item.name}</h1>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: 14,
                    marginLeft: 10,
                    color: "rgba(255,255,255,0.6)",
                  }}
                >
                  {item.user}
                </p>
                <p style={{ textAlign: "left", fontSize: 18, marginLeft: -50 }}>
                  {item.comentario}
                </p>
              </div>
            </div>
          ))}
        </div>
      </header>
    </div>
  );
}

export default Live;
