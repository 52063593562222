import "./login.css";
import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  Alert,
  Button,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { auth, db } from "../config/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Navigate } from "react-router-dom";


const Registro = () => {
    const [showPassword, setShowPassword] = React.useState(false);
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const [password, setPassword] = React.useState("");
  
    const [email, setEmail] = React.useState("");
  
    const handleChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleChangeEmail = (event) => {
      setEmail(event.target.value);
    };
  
    const [success, setSuccess] = React.useState(false);
  
    const [error, setError] = React.useState(false);
  
    const handleSubmit = () => {
      setSuccess(!success);
  
      console.log(email, password);
    };
  
    const SignIn = async (e) => {
      if (email == "" && password == "") {
        console.log("Favor de llenar todos los datos");
      } else {
        await signInWithEmailAndPassword(auth, email, password)
          .then(handleSubmit)
          .catch((error) => {
            console.log(error);
            setError(true);
            setTimeout(() => {
              setError(false);
            }, 6000)
            
          });
      }
    };
  
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          backgroundColor: "transparent",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
          position: "relative",
          padding: 50,
        }}
      >
        <div className="img_login"></div>
        <header className="App-header_login">
          {error === true &&
            <Alert sx={{ alignSelf: 'flex-end', marginBottom: '15px' }} variant="filled" severity="error">
              Inicio de sesion incorrecto, favor de verificar sus datos.
            </Alert>
          }
          <div className="box_black">
            <img
              style={{
                height: 100,
                width: 200,
                alignSelf: "center",
              }}
              src="https://firebasestorage.googleapis.com/v0/b/turixcamapp.appspot.com/o/Web%2FIconos%2F03.png?alt=media&token=c3f750b4-9078-410f-8a9b-9effe21331a3"
            />
            <h1 className="title_login">Crear cuenta</h1>
  
            <Box
              component="form"
              sx={{
                "& > :not(style)": {
                  m: 5,
                  display: "flex",
                  width: "40ch",
                  justifyContent: "center",
                },
              }}
              noValidate
              autoComplete="off"
            >

            <TextField
            sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
                required
            
                label="Nombre(s)"
                color="warning"
                variant="filled"
                fullWidth
                focused
            />

<TextField
            sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
              }}
                required
            
                label="Apellido(s)"
                color="warning"
                variant="filled"
                fullWidth
                focused
            />
            
    
            <TextField
                sx={{
                  backgroundColor: "rgba(255,255,255,0.7)",
                  borderRadius: "5px",
                }}
                required
                label="Correo"
                color="warning"
                fullWidth
                variant="filled"
                focused
                value={email}
                onChange={(e) => handleChangeEmail(e)}
              />

              
              
              <FormControl
                sx={{
                  m: 1,
                  width: "",
                  backgroundColor: "rgba(255,255,255,0.7)",
                  borderRadius: "5px",
                }}
                color="warning"
                focused
                variant="filled"
                value={password}
                onChange={(e) => handleChange(e)}
              >    
        
                <InputLabel htmlFor="filled-adornment-password">
                  Contraseña
                </InputLabel>
                
                <FilledInput
                  id="filled-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="warning"
                        aria-label={
                          showPassword
                            ? "hide the password"
                            : "display the password"
                        }
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />    
              </FormControl>

              <FormControl
                sx={{
                  m: 1,
                  width: "",
                  backgroundColor: "rgba(255,255,255,0.7)",
                  borderRadius: "5px",
                }}
                color="warning"
                focused
                variant="filled"
                value={password}
                onChange={(e) => handleChange(e)}
              >

                
            
                <InputLabel htmlFor="filled-adornment-password">
                  Confirmar Contraseña
                </InputLabel>
                
                <FilledInput
                  id="filled-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="warning"
                        aria-label={
                          showPassword
                            ? "hide the password"
                            : "display the password"
                        }
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />    
              </FormControl>

            </Box>
            <Button
              onClick={(e) => SignIn(e)}
              sx={{ padding: "12px", borderRadius: "10px", width: "45%" }}
              color="warning"
              variant="contained"
            >
              Registrarte
            </Button>
            <a style={{ margin: 10, fontSize: 14 }}>¿Ya tienes cuenta? </a>
            <Button
              sx={{ padding: "12px", borderRadius: "10px", width: "45%" }}
              color="warning"
              variant="contained"
            >
              Inicia sesion
            </Button>
            {success === true && <Navigate to={"home"} />}
          </div>
        </header>
      </div>
    );
  };
  


export default Registro
